import React, { useContext, useEffect } from "react";
import { Row, Col, Tab, Spinner } from "react-bootstrap";

import StripeForm from "../../components/StripeForm";
import { useConfirmPayament } from "../../mutations";
import { CartContext, LocationsContext, RestaurantContext } from "../../context";
import { StaticImage } from "gatsby-plugin-image";
import UpdateCheckoutTip from "./updateTip";

import _ from "lodash";
import { useTrans } from "../../hooks";
import ApplyOffer from "../../components/ApplyOffer";

const CheckoutPayment = () => {
  const { data, error, setData: setVariable } = useConfirmPayament();
  const transMsg = useTrans();
  const {
    cartToken,
    cartTotals,
    cartPayment,
    setCartPayment,
    cartBeingProcessed,
    canDeliver,
  } = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);
  const { restaurant } = useContext(RestaurantContext);

  useEffect(() => {
    if (_.isEmpty(cartToken) || _.isUndefined(cartTotals?.total) || cartTotals?.total < 1)
      return;
    // check if we have canDeliver value
    if (!canDeliver) {
      console.log("there is no can deliver yet, wait for the update cart context to start");
      return;
    }

    setVariable({
      variables: {
        cartToken: cartToken,
        amount: cartTotals.total,
        restaurant: process.env.GATSBY_STRAPI_RESTAURANT_ID,
        location: locationsContext.selectedLocation.id,
        stripeAccountId: locationsContext.selectedLocation.stripeAccountId,
        id: cartPayment?.id || "",
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTotals?.total, locationsContext?.selectedLocation?.stripeAccountId, canDeliver]);

  useEffect(() => {
    if (_.isEmpty(data)) return;
    setCartPayment(data.confirmPayament);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) console.log(JSON.stringify(error));
  }, [error]);

  return (
    <>
      {!_.isEmpty(cartToken) && (
        <>
          <div className="row">
            <div className="col-12 col-lg-12  pt-5 pb-3 ">
              <ApplyOffer />
            </div>
          </div>

          {restaurant.settings?.features?.enableTip && (
            <div className="row ">
              <div className="col-12 col-lg-12  ">
                <UpdateCheckoutTip />
              </div>
            </div>
          )}

          <div className="offer-dedicated-body-left">
            <div className="pt-2"></div>
            <div className="">
              <h6 className="mb-0 text-black-50">
                <div className="row ">
                  <div className="col-5">
                    {/* <Icofont icon="credit-card" /> Payment Type:{" "}
                     */}
                    <label
                      className="my-1 mr-sm-2 mb-2 h6-text"
                      htmlFor="inlineFormInputSelectTime"
                    >
                      <i
                        className="icofont-card icofont-1x mr-1"
                        style={{ color: "rgb(41, 41, 41)" }}
                      ></i>
                      {transMsg("payment")}:
                    </label>
                  </div>
                  <div className="col-2">
                    {cartBeingProcessed && <Spinner animation="border" variant="info" />}
                  </div>
                  <div className="col-5 text-right">
                    <StaticImage
                      src="../../images/stripe/Powered by Stripe - green.svg"
                      alt="Stripe"
                      width={125}
                    />
                  </div>
                </div>
              </h6>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col>
                    <Tab.Content
                      className="h-100"
                      style={{ borderRadius: "25px", background: "none" }}
                    >
                      <Tab.Pane eventKey="first">
                        <StripeForm />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CheckoutPayment;
